import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

const SustainabilityGridOne = () => {
  return (
    <section className="sustainability-grid-one">
      <div className="relative w-screen flex justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 w-screen lg:w-1000 gap-x-20 gap-y-8 lg:gap-y-28 -mt-12 sm:-mt-16 lg:mt-0 lg:pt-28 pb-16 md:pb-36 z-30">
          <div className="flex flex-col justify-center items-start lg:items-center order-2 lg:order-2 px-4 lg:px-0 pb-12 lg:pb-0">
            <div className="max-w-500">
              <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2 w-full md:pl-8 ">
                <FormattedMessage id="sustainability_emissions_heading" />
              </h2>
              <div className="md:pl-8 md:border-l md:border-gray-100 mt-6">
                <h3 className="text-base font-medium text-gray-700 w-full ">
                  <FormattedMessage id="sustainability_emissions_increase_title" />
                </h3>
                <p className="text-sm font-light text-gray-500 mt-3 w-full ">
                  <FormattedMessage id="sustainability_emissions_increase_text" />
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end order-1 lg:order-2 lg:relative px-4 lg:px-0">
            <div className="max-h-[16rem] sm:h-80 sm:max-h-full w-96 sm:w-120 rounded-md overflow-hidden lg:absolute lg:-top-40 lg:right-0 shadow-3xl">
              <StaticImage
                src="../../images/backgrounds/power_6.jpg"
                alt="Power cable tower"
                placeholder="blurred"
                layout="constrained"
                loading="eager"
                width={720}
                height={480}
              />
            </div>
          </div>
          <div className="flex justify-end order-3  lg:relative px-4 lg:px-0">
            <div className="max-h-[16rem] sm:h-80 sm:max-h-full w-96 sm:w-120 bg-gray-800 rounded-md overflow-hidden px-4 py-4 sm:py-8 md:px-12 lg:absolute lg:-bottom-20 lg:left-0 flex items-center shadow-3xl">
              <div className="w-full mx-auto opacity-100 relative">
                <StaticImage
                  src="../../images/partner_logos/climate_collage.png"
                  alt="Climate organisations"
                  placeholder="blurred"
                  layout="constrained"
                  loading="lazy"
                  width={720}
                  height={480}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start lg:items-center order-4 px-4 lg:px-0 pb-12 lg:pb-0">
            <div className="max-w-500">
              <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2 w-full md:pl-8">
                <FormattedMessage id="sustainability_cta_heading" />
              </h2>
              <div className="md:pl-8 md:border-l md:border-gray-100 mt-6">
                <h3 className="text-base font-medium text-gray-700 w-full ">
                  <FormattedMessage id="sustainability_cta_title" />
                </h3>
                <p className="text-sm font-light text-gray-500 mt-3 w-full ">
                  <FormattedMessage id="sustainability_cta_text" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityGridOne;
