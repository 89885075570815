import React from "react";

import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import SustainabilityHero from "../components/sustainability/hero";
import SustainabilityRecommendations from "../components/sustainability/recommendations";
import SustainabilityGridOne from "../components/sustainability/gridOne";
import SustainabilityGridTwo from "../components/sustainability/gridTwo";
import SustainabilityWhitepaper from "../components/sustainability/whitepaper";

const Sustainability = () => {
  return (
    <Layout isLight={true}>
      <Seo
        title="Sustainability"
        description="Electricity's role in fighting climate change"
      />
      <SustainabilityHero></SustainabilityHero>
      <SustainabilityGridOne></SustainabilityGridOne>
      <SustainabilityWhitepaper></SustainabilityWhitepaper>
      <SustainabilityGridTwo></SustainabilityGridTwo>
      <SustainabilityRecommendations></SustainabilityRecommendations>
    </Layout>
  );
};
export default Sustainability;
