import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import Whitepaper from "../../downloads/reel_whitepaper_august_2021.pdf";

import { FaCloudDownloadAlt } from "@react-icons/all-files/fa/FaCloudDownloadAlt";

const SustainabilityWhitepaper = () => {
  return (
    <section className="sustainability-white-paper">
      <div className="w-screen relative">
        <div className="flex flex-col justify-center items-center w-screen pt-12 lg:pt-16 pb-20 md:pb-36 bg-[#FAFAFA] px-4 lg:px-0">
          <div className="w-full lg:w-1000">
            <div className="font-regular text-base py-4 text-blueish-400 md:pl-8 ">
              White paper
            </div>
            <h2 className="text-2xl md:text-4xl font-medium py-2 text-gray-700 md:pl-8 ">
              <FormattedMessage id="sustainability_whitepaper_title" />
            </h2>
            <div className="text-base font-light lg:w-96 lg:w-full lg:text-left mt-6 lg:pr-28 w-full md:max-w-[700px] text-[#9797A1] md:pl-8 md:border-l md:border-gray-150">
              <div>
                <FormattedMessage id="sustainability_whitepaper_text" />
              </div>
              <div className="underline mt-4">
                <a href={Whitepaper} download>
                  <FormattedMessage id="sustainability_whitepaper_download" />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1000 bg-gray-100 rounded-md flex flex-col justify-center items-center h-full text-white py-6 md:py-12 z-20 mt-12 relative px-4">
            <a href={Whitepaper} download>
              <div className="w-full md:w-auto md:h-[500px] relative group">
                <div className="w-full h-full shadow-xl">
                  <StaticImage
                    src="../../images/graphics/whitepaper.png"
                    alt="Preview of white paper"
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    height={500}
                  />
                </div>
                <div className="absolute top-0 left-0 w-full h-full z-20 bg-blueish-400 opacity-40 lg:hidden group-hover:block cursor-pointer">
                  <div className="w-full h-full flex justify-center items-center text-4xl">
                    <FaCloudDownloadAlt />
                  </div>
                </div>
              </div>
            </a>
            <div className="px-4 lg:px-12 flex w-full justify-center items-center z-10"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityWhitepaper;
