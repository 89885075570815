import React, { createRef, useEffect, useRef } from "react";

import { FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import lottie from "lottie-web";
import SolarCellAnimation from "../../images/animated_icons/data.json";



const SustainabilityRecommendations = () => {
  let animationContainer = createRef();

  const anim = useRef(null);

  useEffect(() => {
    anim.current = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: SolarCellAnimation,
    });
    return () => anim.current.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleStop() {
    anim.current.pause();
  }

  function handleStart() {
    anim.current.play();
  }

  return (
    <section className="sustainability-recommendations">
      <div className=" w-screen flex justify-center bg-[#FDFDFD] overflow-hidden">
        <div className="flex flex-col w-screen lg:w-1000 xl:px-0 py-12 md:pt-16 md:pb-36 z-20 px-4">
          <div className="max-w-3xl mt-4">
            <div className="font-regular text-base py-4 text-blueish-400 md:pl-8 ">
              <FormattedMessage id="sustainability_recommendations_tag" />
            </div>
            <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2 md:pl-8 ">
              <FormattedMessage id="sustainability_recommendations_heading" />
            </h2>
            <div className="text-lg lg:w-full lg:text-left mt-6 lg:pr-28 w-full md:max-w-[700px] text-[#9797A1] md:pl-8 md:border-l md:border-gray-150">
              <FormattedMessage id="sustainability_recommendations_sub_heading" />
            </div>
          </div>
          <div className="max-w-1080 grid grid-cols-1 sm:grid-cols-2 gap-y-16 py-8 mt-12">
            <div className="pl-8 sm:pr-8 lg:pr-16 border-l border-gray-200">
              <div className="lg:pr-16 ">
                <div className="w-10 lg:w-12 mb-4">
                  <StaticImage
                    src="../../images/icons/buy_green.png"
                    alt="Buy green electricity icon"
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    width={96}
                  />

                  {/* <div
                    className="animation-container w-24 h-24"
                    ref={animationContainer}
                    onMouseEnter={handleStart}
                    onMouseLeave={handleStop}
                  ></div> */}
                </div>
                <h3 className="text-gray-700 text-base font-medium w-full mt-6 lg:mt-0 principle-header ">
                  <FormattedMessage id="sustainability_recommendations_buy_title" />
                </h3>
                <p className="mt-4 text-gray-500 text-sm font-light">
                  <FormattedMessage id="sustainability_recommendations_buy_text" />
                </p>
                <span className="underline block mt-4 text-gray-500 text-sm hover:text-gray-700">
                  <Link to="/solution">
                    <FormattedMessage id="sustainability_recommendations_buy_solution_link" />
                  </Link>
                </span>
              </div>
            </div>
            <div className="pl-8 sm:pr-8 lg:pr-16 border-l border-gray-200">
              <div className="lg:pr-16 ">
                <div className="w-10 lg:w-12 mb-4">
                  <StaticImage
                    src="../../images/icons/reduce.png"
                    alt="Reduce electricity consumption icon"
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    width={96}
                  />
                </div>
                <h3 className="text-gray-700 text-base font-medium w-full mt-6 lg:mt-0 principle-header ">
                  <FormattedMessage id="sustainability_recommendations_reduce_title" />
                </h3>
                <p className="mt-4 text-gray-500 text-sm font-light">
                  <FormattedMessage id="sustainability_recommendations_reduce_text" />
                </p>
              </div>
            </div>
            <div className="pl-8 sm:pr-8 lg:pr-16 border-l border-gray-200">
              <div className="lg:pr-16 ">
                <div className="w-10 lg:w-12 mb-4">
                  <StaticImage
                    src="../../images/icons/flexible.png"
                    alt="Introduce flexible electricity consumption"
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    width={96}
                  />
                </div>
                <h3 className="text-gray-700 text-base font-medium w-full mt-6 lg:mt-0 principle-header ">
                  <FormattedMessage id="sustainability_recommendations_flexible_title" />
                </h3>
                <p className="mt-4 text-gray-500 text-sm font-light">
                  <FormattedMessage id="sustainability_recommendations_flexible_text" />
                </p>
              </div>
            </div>
            <div className="sm:mr-8 lg:mr-32 py-10 bg-gray-100  rounded-md px-8">
              <div className="flex flex-col jusitify-center">
                <h3 className="text-gray-700 text-base font-medium w-full lg:mt-0 principle-header ">
                  <FormattedMessage id="sustainability_climate_tool" />
                </h3>
                <p className="text-gray-500 text-base font-light w-full mt-4">
                  <FormattedMessage id="sustainability_recommendations_cta_text" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityRecommendations;
